export const JOINT = 'joint';
export const INDIVIDUAL = 'individual';
export const IS_CAP_ONE_DISABLE_PARAM = 'capOneAppDisable';
export const FINANCING_FF_APP_DISABLED = 'financing-ff-app-disabled';
export const PRE_QUALIFICATION_APPLICATION = 'Pre-qualification Application';
export const PRE_QUALIFICATION_RESULT = 'Pre-qualification Results';
export const CAPITAL_ONE_LOGO_SRC = '/img/car-buying/capital1/capital_one.png';
export const CAPITAL_ONE_LOGO_SRC_NON_PARTNER = '/img/car-buying/capital1/cap-one3.png';
export const KNOW_YOUR_BUYING_POWER = 'Know Your Buying Power';
export const SEE_PAYMENT_ON_ELIGIBLE_CARS = 'See your real payments on eligible cars.';
export const PREQUALIFY_TO_SEE_YOUR_PAYMENTS_ON_ELIGIBLE_CARS =
  'Prequalify to see your actual payments on eligible cars';
export const DISCOVER_YOUR_REAL_PAYMENT = 'Discover Your Real Payment';
export const CAPITAL_ONE_ENTRY_POINT_CONTENT = {
  TITLE: 'See If You Prequalify',
  SUBTITLE: 'Get prequalified in minutes with no impact to your credit score from Capital One.',
  CTA_TEXT: 'Get prequalified',
  CAP_ONE_TITLE: 'Curious if you can afford it?', // TODO: should be removed when clean up TED-6423-C1-VDP-BuyingPower
};

export const PRE_QUALIFICATION_CALCULATOR = 'Prequalify to see your real payments on Edmunds';
const NO_IMPACT = {
  text1: 'No impact',
  text2: 'on credit score',
  icon: '/img/car-buying/capital1/credit_card_green.svg',
  width: 33,
};
const DECISION = {
  text1: 'Decision in',
  text2: 'minutes',
  icon: '/img/car-buying/capital1/fast_stopwatch_green.svg',
  width: 35,
};
const DECISION_IN_MINUTES = {
  text1: 'Decision in minutes',
  text2: '',
  icon: '/img/car-buying/capital1/fast_stopwatch_green.svg',
  width: 35,
};
const RATE_PAYMENT = {
  text1: 'Real rates and',
  textIneligible1: 'Real payments',
  text2: 'payments',
  textIneligible2: 'on eligible cars',
  icon: '/img/car-buying/capital1/hand_with_coin_green.svg',
  width: 30,
};
const RATE_PAYMENT_ELIGIBLE_CAR = {
  text1: 'Real payments',
  text2: 'on eligible cars',
  icon: '/img/car-buying/capital1/hand_with_coin_green.svg',
  width: 30,
};
export const ENTRYPOINT_ICONS_WITH_TEXT = [NO_IMPACT, DECISION, RATE_PAYMENT];
export const ENTRYPOINT_ICONS_WITH_TEXT_INTEREST_CALCULATOR = [NO_IMPACT, DECISION_IN_MINUTES, RATE_PAYMENT];
export const ENTRYPOINT_ICONS_WITH_TEXT_CALCULATOR = [NO_IMPACT, DECISION, RATE_PAYMENT_ELIGIBLE_CAR];
export const OTHER = 'other';
export const USED_SUMMARY_CHECKOUT = 'used-summary_checkout';

export const CAPITAL_ONE_ENTRY_POINT_PHOTOFLIPPER = {
  CTA_TEXT: 'Get Prequalified',
  CTA_TEXT_MOBILE: 'See if you prequalify',
  DRIVEWAY_CTA_TEXT: 'Get It Delivered',
  BENEFITS: ['No impact\n on credit score', 'Decision in\n minutes', 'Real rates and\n payments'],
  TEXT: 'Prequalify with no impact on your credit score',
  DRIVEWAY_TEXT: '7 day return period and online checkout',
  HEADER_MOBILE: 'See Your Payments on Edmunds',
};

export const TRACKING_BOL_BTN_CLICK = 'bol_btn_click';
export const TRACKING_FINANCING_CTA = 'financing_cta';

// TODO: should be removed when clean up TED-6324-C1-Appraisal-Inv-Entry
export const CAPITAL_ONE_ENTRY_POINT_APPRAISAL = {
  'chal-1': {
    header: 'Prequalify to see your real payments on Edmunds',
    headerClasses: 'size-20 font-weight-bold text-cool-gray-30 mb-0',
    benefits: [
      { icon: '/img/car-buying/capital1/credit_card_green.svg', width: 33, text: 'No impact \non credit \nscore' },
      { icon: '/img/car-buying/capital1/fast_stopwatch_green.svg', width: 35, text: 'Decision in \nminutes' },
      {
        icon: '/img/car-buying/capital1/hand_with_coin_green.svg',
        width: 30,
        text: 'Real \npayments on \neligible cars',
      },
    ],
    ctaText: 'Get Prequalified',
  },
  'chal-2': {
    header: 'Prequalify \n& Shop',
    headerClasses: 'size-30 font-weight-bold text-cool-gray-10 header',
    benefits: [
      { header: 'Prequalify', description: `with <strong>no impact</strong> to your credit score` },
      { header: 'Shop', description: 'knowing your monthly payment on eligible cars' },
      { header: 'Buy', description: `smarter by sending <strong>your</strong> terms to the dealer` },
    ],
    imageSrc: '/img/car-buying/capital1/monthly-payment-table-with-car.png',
    imageMobileSrc: '/img/car-buying/capital1/monthly-payment-table.png',
    ctaText: 'Get Prequalified',
  },
};

export const CAPITAL_ONE_ENTRY_POINT_APPRAISAL_ELIGIBLE = {
  header: 'Looking to buy this car? ',
  subheader: 'Prequalify and see your actual payment on Edmunds. \nNo impact to your credit score.',
  subheaderMobile: 'Prequalify and see your actual \npayment on Edmunds. \nNo impact to your credit score.',
  ctaText: 'Get Prequalified',
};

// TODO: should be removed when clean up TED-6337-C1-TrackCarValue-Entry
export const CAPITAL_ONE_ENTRY_POINT_TRACK_CAR_VALUE = {
  'chal-1': {
    header: 'Prequalify to see your real payments on Edmunds',
    headerClasses: 'size-20 font-weight-bold text-cool-gray-30 mb-1_5',
    benefits: [
      {
        icon: '/img/car-buying/capital1/credit-card-checkmark-gray.svg',
        width: 33,
        text: 'No impact \non credit score',
      },
      { icon: '/img/car-buying/capital1/fast-stopwatch-gray.svg', width: 35, text: 'Decision in \nminutes' },
      {
        icon: '/img/car-buying/capital1/hand-with-coin-gray.svg',
        width: 30,
        text: 'Real payments \non eligible cars',
      },
    ],
    ctaText: 'Get prequalified',
    ctaClasses: 'py-0_5 py-md-0_75 px-2',
  },
  'chal-2': {
    header: 'Curious what car you can afford if \nyou sell or trade-in your car?',
    headerClasses: 'size-20 font-weight-bold text-cool-gray-30 mt-0_5 mb-1_5',
    benefits: [
      {
        icon: '/img/car-buying/capital1/credit-card-checkmark-blue.svg',
        width: 33,
        text: 'No impact to your credit score',
      },
      { icon: '/img/car-buying/capital1/fast-stopwatch-blue.svg', width: 35, text: 'Decision in minutes' },
      {
        icon: '/img/car-buying/capital1/bullseye-icon-blue.svg',
        width: 30,
        text: 'See payments on Edmunds that \nfactor in your trade-in',
      },
    ],
    ctaText: 'Get prequalified',
    ctaClasses: 'rounded-4 bg-blue-40 px-1_5',
  },
  'chal-3': {
    header: 'Discover Your Buying Power',
    headerClasses: 'size-30 font-weight-bold text-cool-gray-10 mb-1',
    subheader: 'Now that you know what your trade-in is worth, find out what you can prequalify for.',
    disclaimer:
      "Your payment will factor in your trade-in's value so you know what you can afford. No impact on your credit score.",
    benefits: [
      {
        header: 'Your Trade-in',
        headerClass: 'size-12',
        description: "This is your trade-in's value today.",
        imageSrc: '/img/car-buying/capital1/gray-car.png',
        imageWidth: 114,
      },
      {
        header: 'Your Financing Rate',
        headerClass: 'size-20',
        description: 'Prequalify on Edmunds. No impact to your credit score.',
        imageSrc: '/img/car-buying/capital1/percent-coin-over-hand.svg',
        imageWidth: 77,
        sign: '+',
      },
      {
        header: 'Your Buying Power',
        headerClass: 'size-20',
        description: 'Your payment will factor in your trade-in’s value so you know what you can afford.',
        imageSrc: '/img/car-buying/capital1/bullseye-with-dart.png',
        imageWidth: 77,
        sign: '=',
      },
    ],
    ctaText: 'Get Prequalified',
    ctaClasses: 'rounded-4 bg-blue-50 px-2',
  },
};

// TODO: should be removed when clean up TED-6423-C1-VDP-BuyingPower
export const CAPITAL_ONE_ENTRY_POINT_VDP = {
  'chal-1': {
    header: 'Discover Your Buying Power',
    headerStyles: { marginTop: '8px' },
    benefits: [
      {
        icon: '/img/car-buying/capital1/credit-card-checkmark-blue.svg',
        width: 33,
        text: 'No impact \non credit score',
      },
      { icon: '/img/car-buying/capital1/fast-stopwatch-blue.svg', width: 35, text: 'Decision in \nminutes' },
      {
        icon: '/img/car-buying/capital1/bullseye-icon-blue.svg',
        width: 30,
        text: 'Real payments \non eligible cars',
      },
    ],
    ctaText: 'See If You Prequalify',
  },
  'chal-2': {
    header: 'Curious what cars you can afford?',
    headerStyles: { marginTop: '8px' },
    benefits: [
      {
        icon: '/img/car-buying/capital1/credit-card-checkmark-blue.svg',
        width: 33,
        text: 'No impact \non credit score',
      },
      { icon: '/img/car-buying/capital1/fast-stopwatch-blue.svg', width: 35, text: 'Decision in \nminutes' },
      {
        icon: '/img/car-buying/capital1/bullseye-icon-blue.svg',
        width: 30,
        text: 'Real payments \non eligible cars',
      },
    ],
    ctaText: 'See If You Prequalify',
  },
  'chal-3': {
    header: 'Discover Your Buying Power',
    headerStyles: { marginTop: '4px' },
    benefits: [
      {
        icon: '/img/car-buying/capital1/credit_card_green.svg',
        width: 33,
        text: 'No impact \non credit score',
      },
      { icon: '/img/car-buying/capital1/fast_stopwatch_green.svg', width: 35, text: 'Decision in \nminutes' },
      {
        icon: '/img/car-buying/capital1/bullseye-icon.svg',
        width: 30,
        text: 'Real payments \non eligible cars',
      },
    ],
    ctaText: 'See If You Prequalify',
  },

  'chal-4': {
    header: 'Discover Your Buying Power',
    headerStyles: { marginTop: '' },
    benefits: [
      {
        header: 'Your Financing Rate',
        headerStyles: { fontSize: '20px', fontWeight: 'bold' },
        imageSrc: '/img/car-buying/capital1/percent-coin-over-hand.svg',
        imageWidth: 44,
        imageHeight: 46,
      },
      {
        header: 'Your Trade-in',
        headerStyles: { fontSize: '20px', fontWeight: 'bold' },
        imageSrc: '/img/car-buying/capital1/gray-car.png',
        imageWidth: 57,
        imageHeight: 34,
        sign: '+',
      },
      {
        header: 'Your Buying Power',
        headerStyles: { fontSize: '20px', fontWeight: 'bold' },
        imageSrc: '/img/car-buying/capital1/bullseye-with-dart.png',
        imageWidth: 40,
        imageHeight: 38,
        sign: '=',
      },
    ],
    ctaText: 'See If You Prequalify',
  },
};

// TODO: should be removed when clean up TED-6443-C1-UsedSRP-Entry
export const CAPITAL_ONE_ENTRY_POINT_SRP = {
  'chal-1': {
    header1: 'Discover Your Buying Power',
    subHeader: 'Find out what you can prequalify for and see your actual payments on eligible cars.',
    headerStyles: { marginTop: '' },
    benefits: [
      {
        header: 'Your Financing Rate',
        headerClass: 'size-20 font-weight-bold',
        imageSrc: '/img/car-buying/capital1/percent-coin-over-hand.svg',
        imageWidth: 77,
        description: 'Prequalify on Edmunds. No impact to your credit score.',
        benefitCardClass: 'mx-md-1_25 mx-lg-1_5 py-1',
      },
      {
        header: 'Your Trade-in',
        headerClass: 'size-20 font-weight-bold',
        imageSrc: '/img/car-buying/capital1/gray-car.png',
        imageWidth: 114,
        description: 'We help you appraise your vehicle in just a few minutes',
        sign: '+',
        benefitCardClass: 'mx-md-1_25 mx-lg-1_5 py-1',
      },
      {
        header: 'Your Buying Power',
        headerClass: 'size-20 font-weight-bold',
        imageSrc: '/img/car-buying/capital1/bullseye-with-dart.png',
        imageWidth: 77,
        description: 'Your payment will factor in your trade-in’s value so you know what you can afford',
        sign: '=',
        benefitCardClass: 'mx-md-1_25 mx-lg-1_5 py-1',
      },
    ],
    ctaText: CAPITAL_ONE_ENTRY_POINT_CONTENT.CTA_TEXT,
    ctaClasses: 'rounded-4 bg-blue-50 px-2',
    disclaimer: 'No impact on your credit score.',
  },
  'chal-2': {
    header1: 'Prequalify To See Your ',
    header2: 'Real Payments',
    benefits: [
      {
        icon: '/img/car-buying/capital1/credit_card_green.svg',
        width: 33,
        text: 'No impact to your credit score',
      },
      { icon: '/img/car-buying/capital1/fast_stopwatch_green.svg', width: 35, text: 'Decision in minutes' },
      {
        icon: '/img/car-buying/capital1/bullseye-icon.svg',
        width: 30,
        text: 'See payments on Edmunds\n that factor in your trade-in',
      },
    ],
    ctaText: CAPITAL_ONE_ENTRY_POINT_CONTENT.TITLE,
    ctaClasses: 'btn btn-success',
  },
};

// END
// TODO: Should be removed when clean up TED-6457-C1-Appraisal-Offer-Entry
export const CAPITAL_ONE_ENTRY_POINT_APPRAISAL_OFFER = {
  header: 'Discover Your Buying Power',
  headerClasses: 'size-30 font-weight-bold text-cool-gray-10 mb-1',
  subheader: 'Now that you know what your trade-in is worth, find out what you can prequalify for.',
  disclaimer:
    "Your payment will factor in your trade-in's value so you know what you can afford. No impact on your credit score.",
  tradeInDisclaimer:
    "*The offer from CarMax is contingent on your providing accurate information on Edmunds.com. CarMax will conduct an in-person verification of your vehicle and evaluate other vehicle use and history information prior to finalizing the offer. Any differences between the information you provide about your vehicle and the vehicle's actual condition, use, and history may impact the offer you receive from CarMax.",
  expiredTradeInDisclaimer:
    "*This value is Edmunds' estimate of the average price dealers are currently paying for this vehicle in your area. It is not a firm offer on your car.",
  benefits: [
    {
      header: 'Your Trade-in',
      headerClass: 'size-12',
      description: "This is your trade-in's value today.",
      imageSrc: '/img/car-buying/capital1/Trade-In.svg',
      imageWidth: 100,
    },
    {
      header: 'Your Financing Rate',
      headerClass: 'size-20',
      description: 'Prequalify on Edmunds. No impact to your credit score.',
      imageSrc: '/img/car-buying/capital1/FinancingRate.svg',
      imageWidth: 77,
      sign: '+',
    },
    {
      header: 'Your Buying Power',
      headerClass: 'size-20',
      description: 'Your payment will factor in your trade-in’s value so you know what you can afford.',
      imageSrc: '/img/car-buying/capital1/BuyingPower.svg',
      imageWidth: 77,
      sign: '=',
    },
  ],
  ctaText: 'Get Prequalified',
  ctaClasses: 'rounded-4 bg-blue-50',
};
export const FINANCING_PREQUALIFICATION_OFFER_CREATIVE_ID = 'financing-prequalification-offer';
export const FINANCING_PREQUALIFICATION_APPRAISAL_CREATIVE_ID = 'financing-prequalification-appraisal';

// TODO: should be removed when clean up TED-6504-CAF-VDP-Prequal-Clickout
export const CAF_PREQUALIFY_ENTRY_POINT = {
  header: 'See If You Pre-qualify',
  benefits: [
    {
      icon: '/img/car-buying/capital1/credit_card_green.svg',
      width: 33,
      text: 'No impact \non credit score',
    },
    { icon: '/img/car-buying/capital1/fast_stopwatch_green.svg', width: 35, text: 'Decision in \nminutes' },
    {
      icon: '/img/car-buying/capital1/hand_with_coin_green.svg',
      width: 33,
      text: 'Real rates \nand payments',
    },
  ],
  ctaText: 'Get Pre-qualified',
};

export const FINANCING_CAF_CREATIVE_ID = 'used-summary_financing-caf';
export const FINANCING_CAF_ADHESION_PANEL_CREATIVE_ID = 'panel_email_form-caf';
