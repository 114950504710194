import { createModelSegment } from 'client/data/luckdragon/segment';
import { isNode } from 'client/utils/environment';
import { FinancingStorage } from 'client/site-modules/financing/utils/storage';
import { get, set, isEmpty, isNil, merge, pickBy } from 'lodash';
import {
  STORAGE_KEYS,
  DEFAULT_CALCULATOR_SELECTIONS,
  PREQUALIFY_API,
  PREQUALIFY_API_DEV,
  WARN,
} from 'client/site-modules/financing/constants/financing';
import { InventoryModel } from 'client/data/models/inventory';
import { getFormattedOfferData, getVehicleType } from 'site-modules/financing/utils/financing-utils';
import { CapOneAPI, CapOneAPIV2, EdmundsAPI } from 'client/data/api/api-client';
import { ClientConfig } from 'client/configuration';
import { VisitorModel } from 'client/data/models/visitor';
import { getDecryptedFields } from 'client/site-modules/financing/utils/encrypting-utils';
import { logger } from 'client/utils/isomorphic-logger';
import { isCapOneProvider, isEligible } from 'site-modules/shared/utils/financing/financing';
import { getDefaultApiHeaders } from 'site-modules/shared/utils/car-buying/api-helper';
import { withMetrics } from 'client/data/api/api-metrics';
import { awsServiceAccountSignedOptions } from 'client/site-modules/shared/utils/financing/aws-service-account-signed-options';
import { sendMockOffers, shouldMockResponse } from 'site-modules/shared/utils/financing/mock-util';
import { getTaxesFeesApiUrl } from 'site-modules/shared/utils/financing/api';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';

export const MODEL = {
  VIN: `storageData.${STORAGE_KEYS.VIN}`,
  LAST_COMPLETED_STEP: `vins["{vin}"].${STORAGE_KEYS.LAST_COMPLETED_STEP}`,
  VISITED_STEPS: `vins["{vin}"].${STORAGE_KEYS.VISITED_STEPS}`,
  FINANCING_OFFERS: `vins["{vin}"].${STORAGE_KEYS.FINANCING_OFFERS}`,
  FINANCING_OFFERS_BYPASS_API_GATEWAY: `vins["{vin}"].financingOffersBypassApiGateway`,
  CALCULATOR_SELECTIONS: `vins["{vin}"].${STORAGE_KEYS.CALCULATOR_SELECTIONS}`,
  PREQUALIFICATION_DATA: 'prequalificationData',
  ERROR_STATUS: `vins["{vin}"].${STORAGE_KEYS.ERROR_STATUS}`,
  PRE_VIN_ERROR_STATUS: STORAGE_KEYS.PRE_VIN_ERROR_STATUS,
  APPRAISAL_OFFER: STORAGE_KEYS.APPRAISAL_OFFER,
};

export const PATHS = {
  LAST_COMPLETED_STEP: ({ vin }) => `vins["${vin}"].lastCompletedStep`,
  VISITED_STEPS: ({ vin }) => `vins["${vin}"].visitedSteps`,
  FINANCING_OFFERS: ({ vin, isAllowBypassApiGateway = false }) =>
    isAllowBypassApiGateway ? `vins["${vin}"].financingOffersBypassApiGateway` : `vins["${vin}"].financingOffers`,
  CALCULATOR_SELECTIONS: ({ vin }) => `vins["${vin}"].financingCalculatorSelections`,
  PREQUALIFICATION_DATA: 'prequalificationData',
  ERROR_STATUS: ({ vin }) => `vins["${vin}"].errorStatus`,
  PRE_VIN_ERROR_STATUS: 'preVinErrorStatus',
  FINANCING_RESPONSE: 'financingResponse',
  APPRAISAL_OFFER: 'appraisalOffer',
};

function getApiOptions(visitorId) {
  return {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'x-api-key': ClientConfig.get('carBuying').xApiKey,
      'x-visitor-id': visitorId,
    },
    timeout: ClientConfig.get('carBuying').capOneAPI.apiTimeoutMs,
    showAPIError: true,
    retries: 0,
  };
}

function getPayloadData({ vin, vehicle, calculatorSelections, taxesAndFees }) {
  return getFormattedOfferData({
    data: {
      vin,
      make: get(vehicle, 'vehicleInfo.styleInfo.make'),
      model: get(vehicle, 'vehicleInfo.styleInfo.model'),
      year: get(vehicle, 'vehicleInfo.styleInfo.year'),
      condition: getVehicleType(vehicle),
      mileage: get(vehicle, 'vehicleInfo.mileage'),
      salesPrice: get(vehicle, 'prices.displayPrice'),
    },
    calculatorSelections,
    taxesAndFees,
  });
}

export async function getTaxesAndFeesData({ vehicle, calculatorSelections }) {
  const { tradeIn = 0, tradeInOwedAmount = 0, zipCode } = calculatorSelections;
  const styleId = get(vehicle, 'vehicleInfo.styleInfo.styleId');

  let taxesAndFeesResponse = {};

  if (zipCode && styleId) {
    const docFee = get(vehicle, 'prices.docFee', 0);
    const displayPrice = get(vehicle, 'prices.displayPrice', 0);
    const tmv = get(vehicle, 'prices.tmv');
    const vehicleType = get(vehicle, 'type');
    const isNewType = isNew(vehicleType);
    const marketValue = isNewType
      ? tmv || displayPrice
      : get(vehicle, 'computedDisplayInfo.priceValidation.listPriceEstimate', displayPrice);

    try {
      taxesAndFeesResponse = await EdmundsAPI.fetchJson(
        getTaxesFeesApiUrl({
          zipCode,
          styleId,
          tradeIn: tradeIn - tradeInOwedAmount,
          salesPrice: displayPrice,
          marketValue,
        })
      );

      set(taxesAndFeesResponse, 'fees.docFee', Number.parseFloat(docFee));
      set(taxesAndFeesResponse, 'zipCode', zipCode);
    } catch (e) {
      logger('error', `Not able to get taxes and fees. Error - ${e}`);
    }
  }

  return taxesAndFeesResponse;
}

export const FinancingModel = createModelSegment('financing', [
  {
    path: MODEL.VIN,
    async resolve({ vin }) {
      const defaultValue = {};

      if (isNode()) {
        return defaultValue;
      }

      return Promise.resolve(FinancingStorage.getValueFromSessionStorage(vin) || defaultValue);
    },
  },
  {
    path: MODEL.LAST_COMPLETED_STEP,
    async resolve({ vin }) {
      if (isNode()) {
        return undefined;
      }

      return FinancingStorage.getValueFromSessionStorage(`${vin}.${STORAGE_KEYS.LAST_COMPLETED_STEP}`) || null;
    },
    async update(data, { vin }) {
      if (isNode()) {
        return undefined;
      }

      FinancingStorage.setValueToSessionStorage(`${vin}.${STORAGE_KEYS.LAST_COMPLETED_STEP}`, data);
      return Promise.resolve(data);
    },
  },
  {
    path: MODEL.VISITED_STEPS,
    async resolve({ vin }) {
      if (isNode()) {
        return undefined;
      }

      return FinancingStorage.getValueFromSessionStorage(`${vin}.${STORAGE_KEYS.VISITED_STEPS}`) || [];
    },
    async update(step, { vin }) {
      if (isNode()) {
        return undefined;
      }

      const data = FinancingStorage.getValueFromSessionStorage(`${vin}.${STORAGE_KEYS.VISITED_STEPS}`) || [];
      if (data.indexOf(step) === -1) {
        data.push(step);
      }

      FinancingStorage.setValueToSessionStorage(`${vin}.${STORAGE_KEYS.VISITED_STEPS}`, data);

      return Promise.resolve(data);
    },
  },
  {
    path: MODEL.FINANCING_OFFERS,
    async resolve({ vin }, context) {
      const offers = FinancingStorage.getValueFromSessionStorage(`${vin}.${STORAGE_KEYS.FINANCING_OFFERS}`) || [];
      const prequalificationData = await context.resolveValue(PATHS.PREQUALIFICATION_DATA, FinancingModel);

      if (prequalificationData && isEmpty(offers)) {
        const decryptedPrequalifyData = await getDecryptedFields(JSON.parse(prequalificationData));
        const isUpdatePayments = get(decryptedPrequalifyData, 'updatePayments', false);

        if (!isUpdatePayments) {
          if (shouldMockResponse(decryptedPrequalifyData)) {
            return sendMockOffers();
          }

          const id = get(decryptedPrequalifyData, 'id');
          const [vehicle, visitorId] = await Promise.all([
            context.resolveValue(`vin["${vin}"]`, InventoryModel),
            context.resolveValue('id', VisitorModel),
          ]);
          const isCapOne = isCapOneProvider(vehicle);
          const isEligibleVIN = isEligible(vehicle);

          if (id && isCapOne && isEligibleVIN) {
            const appliedTradeIn = decryptedPrequalifyData?.appraisalOffer?.appliedTradeIn;
            const calculatorSelections = appliedTradeIn
              ? {
                  ...get(decryptedPrequalifyData, 'calculatorSelections', DEFAULT_CALCULATOR_SELECTIONS),
                  tradeIn: appliedTradeIn,
                  make: decryptedPrequalifyData.appraisalOffer.make,
                  model: decryptedPrequalifyData.appraisalOffer.model,
                  year: decryptedPrequalifyData.appraisalOffer.year,
                }
              : get(decryptedPrequalifyData, 'calculatorSelections', DEFAULT_CALCULATOR_SELECTIONS);
            const taxesAndFees = await getTaxesAndFeesData({ vehicle, calculatorSelections });
            const data = getPayloadData({ vin, vehicle, calculatorSelections, taxesAndFees });

            try {
              let response;
              let options;
              let signedOptions;
              const isDisableAWSAuth = ClientConfig.get('carBuying').capOneAPI.disableAWSAuth;
              const path = `${ClientConfig.get('carBuying').capOneAPI.path}${PREQUALIFY_API.PRICING}${id}`;

              if (!isDisableAWSAuth) {
                const awsOptions = await awsServiceAccountSignedOptions(data, path);
                options = get(awsOptions, 'options');
                signedOptions = get(awsOptions, 'signedOptions');
              }

              try {
                if (isDisableAWSAuth) {
                  options = getApiOptions(visitorId);
                  response = await withMetrics(CapOneAPI, context).fetchJson(`${PREQUALIFY_API_DEV.PRICING}${id}`, {
                    ...options,
                    body: JSON.stringify(data),
                  });
                } else {
                  const optionsWithVisitorId = { headers: getDefaultApiHeaders(visitorId, true) };
                  response = await withMetrics(CapOneAPI, context).fetchJson(
                    path,
                    merge(signedOptions, optionsWithVisitorId)
                  );
                }

                const offersResponse = get(response, 'offers', []);
                offersResponse.forEach(offer => set(offer, 'vehicleStructure.taxesAndFees', taxesAndFees));
                return offersResponse;
              } catch (e) {
                logger(
                  WARN,
                  isDisableAWSAuth
                    ? `Get offers error is - ${e}.`
                    : `Get offers error is - ${e}. Vehicle pricing polling request params: ${JSON.stringify({
                        options,
                        signedOptions,
                      })}`
                );
                return {
                  isError: true,
                  message: e.message,
                  status: e.status,
                };
              }
            } catch (e) {
              logger('error', 'Not running in ECS container. No AWS credentials available.');

              return { isError: true, message: e.message, status: e.status };
            }
          }
        }
      }

      return offers;
    },
    async update(data, { vin }) {
      FinancingStorage.setValueToSessionStorage(`${vin}.${STORAGE_KEYS.FINANCING_OFFERS}`, data);
      return data;
    },
  },

  {
    path: MODEL.FINANCING_OFFERS_BYPASS_API_GATEWAY,
    async resolve({ vin }, context) {
      const offers = FinancingStorage.getValueFromSessionStorage(`${vin}.${STORAGE_KEYS.FINANCING_OFFERS}`) || [];
      const prequalificationData = await context.resolveValue(PATHS.PREQUALIFICATION_DATA, FinancingModel);

      if (prequalificationData && isEmpty(offers)) {
        const decryptedPrequalifyData = await getDecryptedFields(JSON.parse(prequalificationData));
        const isUpdatePayments = get(decryptedPrequalifyData, 'updatePayments', false);

        if (!isUpdatePayments) {
          if (shouldMockResponse(decryptedPrequalifyData)) {
            return sendMockOffers();
          }

          const id = get(decryptedPrequalifyData, 'id');
          const [vehicle, visitorId] = await Promise.all([
            context.resolveValue(`vin["${vin}"]`, InventoryModel),
            context.resolveValue('id', VisitorModel),
          ]);

          const isCapOne = isCapOneProvider(vehicle);
          const isEligibleVIN = isEligible(vehicle);

          if (id && isCapOne && isEligibleVIN) {
            const appliedTradeIn = decryptedPrequalifyData?.appraisalOffer?.appliedTradeIn;
            const calculatorSelections = appliedTradeIn
              ? {
                  ...get(decryptedPrequalifyData, 'calculatorSelections', DEFAULT_CALCULATOR_SELECTIONS),
                  tradeIn: appliedTradeIn,
                  make: decryptedPrequalifyData.appraisalOffer.make,
                  model: decryptedPrequalifyData.appraisalOffer.model,
                  year: decryptedPrequalifyData.appraisalOffer.year,
                }
              : get(decryptedPrequalifyData, 'calculatorSelections', DEFAULT_CALCULATOR_SELECTIONS);
            const taxesAndFees = await getTaxesAndFeesData({
              vehicle,
              calculatorSelections,
            });
            const data = getPayloadData({ vin, vehicle, calculatorSelections, taxesAndFees });

            try {
              const options = getApiOptions(visitorId);
              const response = await withMetrics(CapOneAPIV2, context).fetchJson(`${PREQUALIFY_API_DEV.PRICING}${id}`, {
                ...options,
                body: JSON.stringify(data),
              });
              const offersResponse = get(response, 'offers', []);
              offersResponse.forEach(offer => set(offer, 'vehicleStructure.taxesAndFees', taxesAndFees));

              return offersResponse;
            } catch (e) {
              logger(WARN, `Get offers error is - ${e}.`);
              return {
                isError: true,
                message: e.message,
                status: e.status,
              };
            }
          }
        }
      }
      return offers;
    },
    async update(data, { vin }) {
      FinancingStorage.setValueToSessionStorage(`${vin}.${STORAGE_KEYS.FINANCING_OFFERS}`, data);
      return data;
    },
  },

  {
    path: MODEL.CALCULATOR_SELECTIONS,
    async resolve({ vin }) {
      if (isNode()) {
        return undefined;
      }

      const storedSelections = FinancingStorage.getValueFromLocalStorage(
        `${vin}.${STORAGE_KEYS.CALCULATOR_SELECTIONS}`
      );

      return Promise.resolve(pickBy(storedSelections, value => !isNil(value)));
    },
    async update(data, { vin }) {
      const dataToSave = {
        ...FinancingStorage.getValueFromLocalStorage(`${vin}.${STORAGE_KEYS.CALCULATOR_SELECTIONS}`),
        ...data,
      };

      FinancingStorage.setValueToLocalStorage(`${vin}.${STORAGE_KEYS.CALCULATOR_SELECTIONS}`, { ...dataToSave });

      return Promise.resolve(pickBy({ ...dataToSave }, value => !isNil(value)));
    },
  },
  {
    path: MODEL.PREQUALIFICATION_DATA,
  },

  {
    path: MODEL.ERROR_STATUS,
    async resolve({ vin }) {
      if (isNode()) {
        return undefined;
      }

      return FinancingStorage.getValueFromSessionStorage(`${vin}.${STORAGE_KEYS.ERROR_STATUS}`) || null;
    },
    async update(data, { vin }) {
      if (isNode()) {
        return undefined;
      }

      FinancingStorage.setValueToSessionStorage(`${vin}.${STORAGE_KEYS.ERROR_STATUS}`, data);
      return Promise.resolve(data);
    },
  },
  {
    path: MODEL.PRE_VIN_ERROR_STATUS,
    async resolve() {
      if (isNode()) {
        return undefined;
      }

      return FinancingStorage.getValueFromSessionStorage(STORAGE_KEYS.PRE_VIN_ERROR_STATUS) || null;
    },
    async update(data) {
      if (isNode()) {
        return undefined;
      }

      FinancingStorage.setValueToSessionStorage(STORAGE_KEYS.PRE_VIN_ERROR_STATUS, data);
      return Promise.resolve(data);
    },
  },
  {
    path: MODEL.APPRAISAL_OFFER,
    async resolve(_, context) {
      if (isNode()) {
        const prequalificationData = await context.resolveValue(PATHS.PREQUALIFICATION_DATA, FinancingModel);
        let appraisalOffer;
        if (prequalificationData) {
          const decryptedPrequalifyData = await getDecryptedFields(JSON.parse(prequalificationData));
          appraisalOffer = decryptedPrequalifyData?.appraisalOffer ?? null;
        }
        return appraisalOffer;
      }

      return FinancingStorage.getValueFromSessionStorage(STORAGE_KEYS.APPRAISAL_OFFER) || null;
    },
    async update(data) {
      FinancingStorage.setValueToSessionStorage(STORAGE_KEYS.APPRAISAL_OFFER, data);
      return Promise.resolve(data);
    },
  },
]);
